import React, {
    useContext, useState, useEffect, useCallback
} from 'react'
import {
    Div, svgSprite, A
} from '../../../hybrid/wrappers'
import { useWindowDimensions } from './../../../common/utils/windowDimensions';
import { Portal } from './../../../hybrid/wrappers';
import Cart from './../../../widgets/cart/Cart';
import UserContext, { StoreContext } from '../../../common/utils/getContext'
import CartFooter from '../../../widgets/cart/CartFooter';
import { navigate } from './../../../common/utils/router';
import { declOfNum } from './../../../common/utils/utils';
import { isWebSite } from '../../../common/utils/utils';
import T from '../../../common/components/T'
import uikit from '../../../project/uikit/styles';

const CartButtonsMain = ({
    styles,
    openCart,
    setOpenCart,
    showFilerForm,
    isMobile
}) => {
    const { lang } = useContext(UserContext)
    const {
        cartData, cart, setCart
    } = useContext(StoreContext)
    const products = cartData && cartData?.items ? cartData?.items : []


    return (
        <Div styles={styles.cartWidgetWrapMain}>
            <A
                title={<Div
                    styles={styles.cartIconMain}
                    effects={{ hover: styles.cartIconMainHover }}
                >
                    {
                        !isMobile ? svgSprite('cart-icon', {
                            style: {
                                width: '24px',
                                height: '23px'
                            }
                        }) : <T textName='menuMobCart' defaultStr='Корзина' page='all'/>
                    }
                    {products.length > 0 && <Div styles={styles.cartIconItemMain}>{products.length}</Div>}
                </Div>}
                href='/[lang]/store/cart'
                as={`/${lang}/store/cart`}
                styles={{ color: '#fff' }}
            />

        </Div>
    )
}
export default CartButtonsMain