// @generated: @expo/next-adapter@2.1.5
import React, {
    useState, useContext, useMemo
} from 'react';
import {
    Div, P, BlankLink, A
} from '../../../hybrid/wrappers';
import UserContext from '../../../common/utils/getContext';
import { getItemMenu } from '../helpers';
import uikit from '../../../project/uikit/styles';
import PropTypes from 'prop-types';

const SingleMenuMain = ({
    styles,
    isMobile,
    active
}) => {
    const { lang, menu } = useContext(UserContext);

    const [showSubMenu, setShowSubMenu] = useState(false);
    const headerMenu = useMemo(() => getItemMenu(menu?.site, lang), [menu?.site, lang]);
    return headerMenu.map((menuItem, idx) => {
        const isActive = menuItem.asLink === active;
        if (!menuItem.activity) return null;
        return (
            <Div
                className={'singleMenu'}
                key={`SingleMenu_${idx}`}
                styles={{ ...styles.wrapperItemMain, ...isMobile && { ...uikit.flexColumn } }}>
                {showSubMenu && <Div data-uitest='659_uitest' onClick={() => { setShowSubMenu(false) }} styles={styles.submenuOverlay} />}
                <Div
                    {...(menuItem.type === 'part' ? { onClick: () => { setShowSubMenu(prev => !prev) } } : {})}
                    styles={styles.mainMenuItemMain}
                    //effects={{ hover: styles.mainMenuItemHover }}
                >
                    <Div styles={{...styles.officeMenuItemTextMain}}>
                        {
                            menuItem.type === 'outLink' ?
                                <P styles={isActive
                                    ? { ...styles.officeMenuItemTextP, ...styles.officeMenuItemTextHover }
                                    : styles.officeMenuItemTextP}
                                >
                                    <BlankLink
                                        styles={styles.itemMenuStyleMainHeader}
                                        title={menuItem.title}
                                        href={`https://${menuItem.asLink}`}
                                        target={menuItem?.target ? '_blank' : '_self'}
                                    />
                                </P>

                                :
                                menuItem.type === 'link' || menuItem.type === 'page' ?
                                    <P styles={isActive
                                        ? { ...styles.officeMenuItemTextP, ...styles.officeMenuItemTextHover }
                                        : styles.officeMenuItemTextP}
                                    effects={{ hover: { ...styles.officeMenuItemTextP, ...styles.itemMenuStyleHover} }}
                                    >
                                        <A styles={styles.itemMenuStyleMainHeader}
                                            effects={{ hover: styles.itemMenuStyleHover }}
                                            title={menuItem.title}
                                            href={`/${lang}${menuItem.type === 'page' ? '/page' : ''}${menuItem.asLink}`}
                                            target={menuItem?.target ? '_blank' : '_self'}
                                        />
                                    </P>
                                    : <P styles={isActive
                                        ? { ...styles.officeMenuItemTextP, ...styles.officeMenuItemTextHover }
                                        : styles.officeMenuItemTextP}
                                    effects={{ hover: { ...styles.officeMenuItemTextP, ...styles.itemMenuStyleHover} }}>
                                        { menuItem.title}
                                    </P>
                        }

                    </Div>
                </Div>

            </Div>
        )
    })
};
SingleMenuMain.propTypes = {
    isMobile: PropTypes.bool,
    active: PropTypes.string,
    styles: PropTypes.object
};

export default SingleMenuMain;