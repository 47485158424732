import React, {
    useContext,
    useCallback
} from 'react';
import Styles from '../../common/decorators/Styles';
import getStyles from '../../project/styles/widget-styles/cart-styles';
import {
    Div,
    svgSprite
} from '../../hybrid/wrappers';
import { navigate } from '../../common/utils/router';
import { useWindowDimensions } from '../../common/utils/windowDimensions';
import UserContext, { StoreContext } from './../../common/utils/getContext';
import T from '../../common/components/T';
import { useStorePackStats } from '../../common/utils/useStorePackStat';

const CartFooter = ({
    styles,
    totalProductsPrice,
    total,
    total_marketing,
    type = 'cart'
}) => {
    const {
        currentUserPack, lastPack, packStat
    } = useStorePackStats() || {};
    const { lang, userToken } = useContext(UserContext);
    const {
        setCart, cart, cartData, setModalCartShow
    } = useContext(StoreContext);

    const cartSumPlusAccumulated = packStat?.current?.id < 3
        ? cartData?.total_marketing_price + packStat?.current?.user_total_personal_volume
        : cartData?.total_marketing_price

    const widthDimensions = useWindowDimensions();
    const screenWidth = widthDimensions < 768;
    const handleClearPrevPayment = useCallback(() => {
        if (localStorage.getItem('finish')) {
            let item = localStorage.getItem('finish');
            if (JSON.parse(item)?.selectedPay?.alias === 'paymentkaspi' || JSON.parse(item)?.data?.qr_token) {
                localStorage.removeItem('finish');
            }
        } else if (localStorage.getItem('answerAfterCheckout')) {
            let item = localStorage.getItem('answerAfterCheckout');
            if (JSON.parse(item)?.selectedPayment?.alias === 'paymentkaspi' || JSON.parse(item)?.data?.qr_token) {
                localStorage.removeItem('answerAfterCheckout');
            }
        }
    }, []);
    return (
        <Div styles={type === 'checkout'
            ? { ...styles.cartFooter, 'justify-content': 'flex-end' }
            : styles.cartFooter}>
            {!screenWidth && type === 'cart' && <Div>
                <Div
                    // htmlFor={attribute}
                    styles={styles.buttonSecondary}
                    data-uitest='534_uitest' onClick={() => setCart(!cart)}
                    effects={{ hover: { ...styles.buttonSecondaryHover } }}
                >
                    {
                        svgSprite('arrow-left', {
                            width: 20, height: 14, fill: styles.variable.blueColor
                        })
                    }
                    <Div styles={{ 'margin-left': '12px' }}><T textName='storeContinieProduct'
                        defaultStr='Продолжить покупки' page='/store'/></Div>
                </Div>
            </Div>}
            <Div styles={styles.cartFooterMobile}>
                <Div>
                    <Div styles={styles.cartItemSubtitle}>
                        <T textName='storeTotalPrice' defaultStr='Всего на сумму' page='/store'/>:
                    </Div>
                    <Div styles={styles.flexRow}>
                        <Div styles={styles.totalPriceMedium}>{totalProductsPrice || total}</Div>
                        <Div styles={styles.totalMarketingPrice}>{total_marketing
                            ? total_marketing
                            : 0}</Div>
                    </Div>
                </Div>
                {type === 'cart' && <Div
                    styles={styles.buttonPrimary}
                    effects={{ hover: { ...styles.formButtonPrimaryHover } }}
                    data-uitest='535_uitest' onClick={() => {
                        handleClearPrevPayment();
                        if (currentUserPack?.id === 4 || currentUserPack?.id >= lastPack?.id || cartSumPlusAccumulated >= lastPack?.price || !userToken) {
                            navigate('/[lang]/store/cart', '/store/cart', lang);
                            setCart(!cart);
                        } else {
                            setModalCartShow(true);
                            setCart(!cart);
                        }
                    }}
                >
                    <T textName='storeOrderCreate' defaultStr='Оформить заказ' page='/store'/>
                </Div>}
            </Div>
            {screenWidth && type === 'cart' && <Div styles={{ width: '100%' }}>
                <Div
                    // htmlFor={attribute}
                    styles={{
                        ...styles.buttonSecondary,
                        'font-size': '1.125rem',
                        width: '100%',
                        'line-height': '1.3334',
                        'font-weight': 500,
                        padding: '1.25rem',
                        margin: '0 0 18px 0'
                    }}
                    data-uitest='534_uitest' onClick={() => setCart(!cart)}
                    effects={{ hover: { ...styles.buttonSecondaryHover } }}
                >
                    {
                        svgSprite('arrow-left', {
                            width: 20, height: 14, fill: styles.variable.blueColor
                        })
                    }
                    <Div styles={{ 'margin-left': '12px' }}><T textName='storeContinieProduct'
                        defaultStr='Продолжить покупки' page='/store'/></Div>
                </Div>
            </Div>}
        </Div>
    );
};
export default Styles(CartFooter, getStyles);